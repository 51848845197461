import React, { useEffect } from 'react';
import data from '../data.json';
import { useLocation, useParams } from 'react-router-dom';
import Card from '../components/Card';
import TitleWithSearch from './TitleWithSearch';
import { Hidden } from '@material-ui/core';
import BrandProduct from './BrandProducts';

function BrandList() {
  const id = useParams().id;
  const brand = useLocation().pathname.split('/')[2];
  const category = useLocation().pathname.split('/')[1];
  var categorydata = data[category];
  var branddata = categorydata.brands[brand];
  var products = branddata.products;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='p-10 bg-slate-200'>
      <Hidden mdDown>
      <TitleWithSearch title={brand} />
      </Hidden>      
      <div className='mx-auto mt-5 w-full md:w-4/5 px-4 border 	rounded-lg shadow-lg mb-5 bg-white'>
      <div className='flex justify-between items-center mb-4 border-b-[4px]'>
      <h2 className='text-3xl font-medium p-5'>{`All products`}</h2>
      </div>
      <div className='mx-auto w-full md:w-full rounded-lg mb-5 bg-white p-5'>
        <div class='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6'>
          {Object.values(products).map((e) => {
            const link = e.url.split('/')[1];
            console.log(link);
            return (
              <div class='flex justify-center rounded-xl bg-white shadow-lg'>
                <BrandProduct
                  title={`${e.name}`}
                  imageurl={`${e.image_src}`}
                  url={`${e.url}`}
                  description={`${e.description}`}
                />
              </div>
            );
          })}
        </div>
      </div>
      </div>
    </div>
  );
}

export default BrandList;
