import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/DropDowns.css";
import dropdown from "../assets/images/dropdown.png";
import data from "../data.json";

function Dropdowns() {
  return (
    <div className="invisible md:visible mx-auto w-full topnav bg-grey-700">
      <ul class=" py-2 px-10">
        <div
          id="scrollContainer"
          className="flex flex-no-wrap scrolling-touch items-center justify-center p-1"
        >
          <li class="dropdown inline px-6 cursor-pointer lg:text-base md:text-sm text-xs uppercase tracking-wide relative lg:pr-8">
            <Link
              to={{
                pathname: `/`,
              }}
            >
              <a className="lg:text-base md:text-sm text-xs">Home</a>
            </Link>
          </li>
          {Object.values(data).map((e) => {
            return (
              <li class="dropdown inline z-0 px-6 hover:z-10 cursor-pointer lg:text-base md:text-sm text-xs uppercase tracking-wide relative lg:pr-8">
                <a className="lg:text-base md:text-sm text-xs">
                  {`${e.fullTitle}`} <img className="inline" src={dropdown} />
                </a>
                <div
                  class="dropdown-menu top-3  hidden h-auto pt-4 absolute"
                  style={{ left: "2rem" }}
                >
                  {Object.values(e.brands).map((k) => {
                    return (
                      <ul class="block w-full bg-white shadow px-3 py-2 z-50">
                        <Link
                          to={{
                            pathname: `${k.url}`,
                          }}
                        >
                          <li class="py-1">
                            <a class="block lg:text-base md:text-sm text-xs uppercase cursor-pointer hover:text-cl-blue">
                              {`${k.name}`}
                            </a>
                          </li>
                        </Link>
                      </ul>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </div>
      </ul>
    </div>
  );
}

export default Dropdowns;
