import React from 'react';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ProductPage from '../components/ProductPage';
import data from '../data.json';

function ProductDetail({name,imageurl,description,location}) {

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const id = useParams().id;
  const category = useLocation().pathname.split('/')[1];
  const brand = useLocation().pathname.split('/')[2];
  const product = useLocation().pathname.split('/')[3];
  var category_data= data[category]
  var product_data=category_data.brands[brand].products[product]
  var related_products=[];
  Object.values(category_data.brands).map((e)=>{
    Object.values(e.products).map((k)=>{
      related_products.push(k);
    })
  })


  
  console.log(related_products)
  return (
    <ProductPage
      title={`${category_data.fullTitle}`}
      name={`${product_data.name}`}
      imageurl={`${product_data.image_src}`}
      description={`${product_data.description}`}
      rel_products={(related_products)}
      rel_url={`/${category_data.url}`}
    />
  );
}

export default ProductDetail;
