import React from "react";
import Card from "../components/Card";
import data from "../data.json";

function OurRange() {
  return (
    <div className="p-5 bg-slate-200">
      {Object.values(data).map((e, i) => {
        return (
          <Card
            key={i}
            title={`${e.fullTitle}`}
            brands={e.brands}
            url={e.url}
          />
        );
      })}
    </div>
  );
}

export default OurRange;
