import { IconButton } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";
import React from "react";
import coollink from "../assets/images/coolinkLogo.png";
import { whatsappText } from "../utils/whatsappText";

function Footer() {
  return (
    <>
      <div className="bg-zinc-900">
        <div className="max-w-screen-xl py-10 px-2 sm:px-2 text-gray-800 sm:flex justify-between mx-auto">
          <div className="justify-center items-center mt-10 sm:w-8/12 ">
            <img className="p-5" src={coollink} />
            <br />
            <div className=" justify-center items-center md:flex">
              <div className="justify-center items-center md:w-1/2">
                <div className="mb-10 p-5 text-center">
                  <h2 className="font-bold text-3xl text-white mb-2">
                    Contact Us
                  </h2>
                  <IconButton
                    onClick={() =>
                      whatsappText(
                        "Hi, I contacted you via the CoolLink Website."
                      )
                    }
                    style={{ color: "green" }}
                  >
                    <WhatsApp />
                  </IconButton>
                  <span className="text-white">+971 56 772 3422</span>
                  <br />
                  <a
                    href="mailto:coollinkllc@gmail.com"
                    target="_blank"
                    referrerPolicy="no-referrer"
                    className="text-white"
                  >
                    coollinkllc@gmail.com
                  </a>
                </div>
              </div>
              <div className="justify-center items-center md:w-1/2">
                <div className="mb-10 p-5 text-center ">
                  <h2 class="font-bold text-3xl text-white mb-2">Address</h2>

                  <a
                    className="text-white"
                    href="https://www.google.com/maps?q=25.26818084716797,55.31474304199219&z=17&hl=en"
                    target="_blank"
                  >
                    View on Google Maps
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" p-5 justify-center sm:w-1/2 rounded-xl">
            <iframe
              className="rounded-xl w-full h-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.071479902568!2d55.312554315010814!3d25.26818078386326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe3e652764388ae13!2zMjXCsDE2JzA1LjUiTiA1NcKwMTgnNTMuMSJF!5e0!3m2!1sen!2sin!4v1647721898353!5m2!1sen!2sin"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
          <div className="p-5 sm:w-1/2 bg-white rounded-xl">
            <form name="contact" method="post" className="mt-4 p-5">
              <input type="hidden" name="form-name" value="contact" />
              <input
                className="border rounded w-full px-4 py-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                type="text"
                name="name"
                placeholder="Enter Your Name"
              />
              <input
                className="border rounded w-full px-4 py-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                type="email"
                name="email"
                placeholder="Enter your Email"
              />
              <input
                className="border rounded w-full px-4 py-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                type="number"
                name="phone"
                placeholder="Enter Your Phone Number"
              />
              <textarea
                className="border rounded w-full px-4 py-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                rows="4"
                cols="5"
                name="message"
                placeholder="Message"
              />
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
