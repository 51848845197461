import Data from "../data.json";

export function searchOptions() {
  var temporary = [];
  const data = JSON.stringify(Data);
  const dataObj = JSON.parse(data);
  //console.log(compBrands);
  const cat = Object.values(dataObj);
  //console.log(cat);
  var Brands = [];
  for (var item in cat) {
    //console.log(cat[item].brands)
    for (var i in cat[item].brands) {
      Brands.push(cat[item].brands[i]);
    }
  }
  //console.log(Brands)
  for (var item in Brands) {
    //console.log(Brands[item]);
    for (var i in Brands[item].products) {
      //console.log(Brands[item].products[i]);
      var obj = { label: "", value: "" };
      obj.label = Brands[item].products[i].name;
      obj.value = Brands[item].products[i].url;
      temporary.push(obj);
    }
  }
  console.log(temporary);
  return temporary;
}
