import React from "react";
import coolLink from "../assets/images/coolinkLogo.png";

function LoadingScreen() {
  return (
    <div className="align-middle justify-center justify-items-center bg-white flex flex-col h-screen">
      <div>
        <img
          alt="Cool Link"
          src={coolLink}
          className="animate-pulse align-middle justify-center m-auto "
        />
      </div>
    </div>
  );
}

export default LoadingScreen;
