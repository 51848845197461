import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import Home from './Home';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PageNotFound from './PageNotFound';
import ProductDetail from './ProductDetail';
import data from '../data.json';
import ProductList from '../components/ProductList';
import BrandList from '../components/BrandList';
import LeftDrawer from '../components/Drawer';
import { Hidden } from '@mui/material';

function MainLayout() {
  return (
    <>
    <BrowserRouter>
    <Hidden mdDown>
    <div className='sticky top-0 z-10'>
    <Navbar />
    </div>    
    </Hidden>
    <Hidden mdUp>
      <LeftDrawer/>
    </Hidden>      
      <div>
        
          <Routes>
            <Route path='/' element={<Home />} />
            {Object.keys(data).map(function (category, i) {
              return (
                <Route key={i} path={`${category}`} element={<ProductList />} />
              );
            })}
            {Object.keys(data).map(function (category, i) {
              return Object.keys(data[category].brands).map(function (
                brand,
                j
              ) {
                return (
                  <Route
                    key={j}
                    path={`/${category}/${brand}`}
                    element={<BrandList />}
                  />
                );
              });
            })}
            {Object.keys(data).map(function (category, i) {
              return Object.keys(data[category].brands).map(function (
                brand,
                j
              ) {
                return Object.keys(data[category].brands[brand].products).map(
                  function (product, k) {
                    return (
                      <Route
                        key={k}
                        path={`/${category}/${brand}/${product}`}
                        element={<ProductDetail />}
                      />
                    );
                  }
                );
              });
            })}
            <Route exact path='*' element={<PageNotFound />} />
          </Routes>
        
      </div>
      <Footer />
      </BrowserRouter>
    </>
  );
}

export default MainLayout;
