import { useEffect, useState } from "react";
import "./App.css";
import Home from "./layouts/Home";
import LoadingScreen from "./layouts/LoadingScreen";
import MainLayout from "./layouts/MainLayout";
import { searchOptions } from "./utils/searchOptions";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 2200);
    searchOptions();
  }, []);
  return loading ? <LoadingScreen /> : <MainLayout />;
}

export default App;
