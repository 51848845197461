import React from "react";
import Select from "react-select";
import "../assets/css/SearchBar.css";
import { searchOptions } from "../utils/searchOptions";
import { useNavigate } from "react-router-dom";
// import data from "../data.json";

const options = searchOptions();

function SearchBar({ handleDrawerClose }) {
  const history = useNavigate();
  return (
    <div className="w-56  md:w-96">
      <Select
        options={options}
        openMenuOnClick={false}
        onChange={(e) => {
          history("/" + e.value);
          handleDrawerClose && handleDrawerClose();
        }}
        placeholder="Search"
        className="z-0"
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
      ></Select>
    </div>
  );
}

export default SearchBar;
