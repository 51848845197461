import React from "react";
import "../assets/css/Advertisement.css";
import { Call } from "@material-ui/icons";
import image1 from "../assets/images/image1.png";
import image2 from "../assets/images/image2.png";
import image3 from "../assets/images/image3.png";
import image4 from "../assets/images/image4.png";

function Advertisement() {
  return (
    <div className="adcontainer flex flex-row ">
      <div className="left w-full xl:w-2/3 md:pl-16 lg:pl-20 xl:pl-36 text-white flex flex-col justify-center pl-2">
        <div className="text m-4 mx-auto mt-10 md:p-4 md:m-8 w-10/12 md:w-8/12 text-center md:text-left">
          Cool Link
          <br /> Air Condition & Refrigeration Spare Parts Trading
        </div>
        <div className="button mx-auto mb-5 md:m-8 p-4">
          <button
            onClick={() => window.scrollTo(100000, 1000000)}
            className="bg-white contact-button rounded-md px-4 py-2"
          >
            Contact Us <Call />
          </button>
        </div>
      </div>

      <div className="xl:w-1/3 right overflow-hidden w-0 flex flex-col align-middle justify-center">
        <div className=" flex flex-row items-center justify-between my-2 mx-16">
          <img className="m-2 p-2 images" src={image1} alt="Image1" />
          <img className="m-2 p-2 images" src={image2} alt="Image2" />
        </div>
        <div className="flex flex-row items-center justify-between my-2 mx-16">
          <img className="m-2 p-4" src={image3} alt="Image3" />
          <img className="m-2 p-4" src={image4} alt="Image4" />
        </div>
      </div>
    </div>
  );
}

export default Advertisement;
