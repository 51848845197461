import { Hidden } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";
import React from "react";
import TitleWithSearch from "./TitleWithSearch";
import Card from "../components/Card";
import { whatsappText } from "../utils/whatsappText";

function ProductPage({
  match,
  title,
  name,
  imageurl,
  description,
  rel_products,
  rel_url,
}) {
  console.log(Object.values(rel_products));
  return (
    <>
      <div className="p-5 bg-slate-200">
        <Hidden mdDown>
          <TitleWithSearch title={title} />
        </Hidden>
        <div class="mx-auto mt-3 w-full md:w-4/5 px-4 border rounded-lg shadow-lg mb-5 bg-white">
          <div className=" w-full md:flex">
            <div className="md:w-1/2 p-5">
              <div class="aspect-auto flex flex-col justify-center items-center">
                <img
                  class="object-cover shadow-md hover:shadow-xl rounded-lg"
                  src={`${imageurl}`}
                  alt=""
                />
              </div>
            </div>
            <div className="md:w-1/2 p-5">
              <h3 className="text-3xl text-black mb-5 font-bold">
                {`${name}`}
              </h3>
              <div className="h-1/2">
                <a className="text-gray-700 h-2/3">{`${description}`}</a>
              </div>
              <div className=" invisible md:visible w-full flex">
                <div className="w-1/2 items-center p-2 ">
                  <button
                    onClick={() =>
                      whatsappText(
                        `Hi, I wish to inquire about the product: *${name}*. Source : Cool Link Website (${window.location.href}). My quote is : `
                      )
                    }
                    class=" text-xl w-full bg-blue-900 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg md:flex flex-col justify-center items-center"
                  >
                    Add Quote
                  </button>
                </div>
                <div className="w-1/2 items-center p-2">
                  <button
                    onClick={() =>
                      whatsappText(
                        `Hi, I wish to inquire about the product: *${name}*.  Source : Cool Link Website (${window.location.href})`
                      )
                    }
                    class="text-xl w-full bg-blue-900 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg md:flex flex-col justify-center items-center "
                  >
                    Get in Touch
                  </button>
                </div>
                <div className="w-1/8 items-center p-2">
                  <button
                    onClick={() =>
                      whatsappText(
                        `Hi, I wish to inquire about the product: *${name}*.  Source : Cool Link Website (${window.location.href})`
                      )
                    }
                    class=" text-xl  bg-green-900 hover:bg-green-700 text-white font-medium py-2 px-4 rounded-lg md:flex"
                  >
                    <WhatsApp />
                  </button>
                </div>
              </div>

              <div className="visible md:invisible flex items-center justify-center items-center p-1">
                <button
                  onClick={() =>
                    whatsappText(
                      `Hi, I wish to inquire about the product: *${name}*. Source : Cool Link Website (${window.location.href}). My quote is : `
                    )
                  }
                  class="w-full text-xl text-white font-medium h-12 px-2 transition-colors duration-150 bg-blue-900 rounded-lg focus:shadow-outline hover:bg-blue-700 "
                >
                  Add Quote
                </button>
              </div>
              <div className="visible md:invisible w-full flex items-center justify-center items-center">
                <div className="w-full items-center grid place-items-center p-1">
                  <button
                    onClick={() =>
                      whatsappText(
                        `Hi, I wish to inquire about the product: *${name}*.  Source : Cool Link Website (${window.location.href})`
                      )
                    }
                    class=" w-full text-xl text-white font-medium h-12 px-2 transition-colors duration-150 bg-blue-900 rounded-lg focus:shadow-outline hover:bg-blue-700"
                  >
                    Get in Touch
                  </button>
                </div>

                <div className="w-2/3 items-center grid place-items-center p-1">
                  <button
                    onClick={() =>
                      whatsappText(
                        `Hi, I wish to inquire about the product: *${name}*. Source : Cool Link Website (${window.location.href})`
                      )
                    }
                    class=" w-full text-xl text-white font-medium h-12 transition-colors duration-150 bg-green-900 rounded-lg focus:shadow-outline hover:bg-green-700"
                  >
                    <WhatsApp />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Card
            title={"Related Products"}
            brands={rel_products}
            url={rel_url}
          />
        </div>
      </div>
    </>
  );
}

export default ProductPage;
