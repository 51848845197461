import React from 'react';
import Select from 'react-select';
import '../assets/css/SearchBar.css';
import SearchBar from './SearchBar';
// import data from "../data.json";

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

function TitleWithSearch({ title }) {
  return (
    <div className='searchbar bg-slate-200 flex flex-row justify-between px-52 mx-auto w-full'>
      <h3 className='z-0 our-range text-3xl uppercase'>{title}</h3>
      <SearchBar />
    </div>
  );
}

export default TitleWithSearch;
