import React from 'react';
import { Link } from 'react-router-dom';

function BrandProduct({ title, imageurl, description, id, url }) {
  return (
    <div class='flex-none w-full md:w-2/3 p-5'>
      <Link
        to={{
          pathname: `/${url}`,
        }}
        
      >
        <div class='space-y-4 flex flex-col justify-center items-center'>
          <div className='h-64 flex flex-col justify-center items-center'>
          <div class='aspect-w-16 aspect-h-9 flex flex-col justify-center items-center'>
            <img
              class='object-cover shadow-md hover:shadow-xl rounded-lg'
              src={`${imageurl}`}
              alt=''
            />
          </div>
          </div>
          <div class='px-4 py-2'>
            <div class='text-lg font-medium space-y-1'>
              <h3 class='font-bold text-gray-800 text-xl mb-2 text-left'>{`${title}`}</h3>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default BrandProduct;
