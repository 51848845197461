import React, { useEffect } from 'react';
import data from '../data.json';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Card from '../components/Card';
import TitleWithSearch from './TitleWithSearch';
import { Hidden } from '@material-ui/core';

function ProductList() {
  const id = useParams().id;
  const category = useLocation().pathname.split('/')[1];

  var categorydata = data[category];
  var brands = categorydata.brands;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='p-5 bg-slate-200'>
      <Hidden mdDown>
      <TitleWithSearch title={categorydata.fullTitle.toUpperCase()} />
      </Hidden>      
      {Object.keys(brands).map((e, i) => {
        console.log(e);
        return (
          <Card
            key={i}
            title={`${e.toUpperCase()}`}
            products={Object.values(data[category].brands[e].products)}
            url={e}
          />
        );
      })}
    </div>
  );
}

export default ProductList;
