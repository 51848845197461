import * as React from "react";
import coollink from "../assets/images/coolinkLogo.png";
import {
  Email,
  Facebook,
  Instagram,
  Twitter,
  WhatsApp,
} from "@material-ui/icons";
import SearchBar from "./SearchBar";
import { whatsappText } from "../utils/whatsappText";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Navbar = () => {
  return (
    <nav class="sticky top-0 z-10 bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded shadow-md">
      <div class="container flex flex-wrap justify-between items-center mx-auto">
        <a href="/" class="flex">
          <img height="12" width="120" src={coollink} />
        </a>
        <div class="flex md:order-2">
          {/* <button type="button" className="p-2">
            <Instagram className="text-cl-blue" />
          </button>
          <button type="button" className="p-2">
            <Twitter className="text-cl-blue" />{" "}
          </button> */}
          <button
            type="button"
            onClick={() =>
              whatsappText(
                "Hi, I would like to enquire regarding products available at CoolLink."
              )
            }
            className="p-2"
          >
            <WhatsApp className="text-cl-blue" />
          </button>
          <button
            type="button"
            onClick={() =>
              window.open(
                "mailto:coollinkllc@gmail.com?subject=Enquiry&body=Hi, I would like to enquire regarding products available at CoolLink.",
                "_blank"
              )
            }
          >
            <Email className="text-cl-blue" />
          </button>
        </div>
        <div
          class="hidden justify-between items-center w-full md:flex md:w-auto md:order-1"
          id="mobile-menu-4"
        >
          <ul class="z-50 flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium ">
            <SearchBar />
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
