import React from 'react';
import { useNavigate } from 'react-router-dom';
import Product from './Product';

function Card({ title, brands, products, url }) {
  console.log(url);
  const navigate = useNavigate();
  return (
    <div className='mx-auto mt-5 w-full md:w-4/5 px-4 border 	rounded-lg shadow-lg mb-5 bg-white'>
      <div className='container my-4  '>
        <div className='flex justify-between items-center mb-4 border-b-[4px]'>
          <h2 className='text-3xl font-medium p-1'>{`${title}`}</h2>
          <div>
            <button
              onClick={() => navigate(url)}
              className=' hidden text-xl mx-1 bg-blue-900 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded mb-2 md:flex'
            >
              See More Products
            </button>
          </div>
        </div>
        <div
          id='scrollContainer'
          className='flex flex-no-wrap overflow-x-scroll scrolling-touch items-start mb-1 shadow-lg p-3'
        >
          {brands
            ? Object.values(brands).map((e) => {
                return (
                  <Product
                    title={e.name}
                    imageurl={e.image_src}
                    url={e.url}
                    description={`${e.description}`}
                  />
                );
              })
            : Object.values(products).map((e) => {
                return (
                  <Product
                    title={e.name}
                    imageurl={e.image_src}
                    url={e.url}
                    description={`${e.description}`}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
}

export default Card;
