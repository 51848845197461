import React from 'react';
import Advertisement from '../components/Advertisement';
import Dropdowns from '../components/Dropdowns';
import SearchBar from '../components/SearchBar';
import OurRange from '../components/OurRange';
import TitleWithSearch from '../components/TitleWithSearch';
import { Hidden } from '@mui/material';

function Home() {
  return (
    <>
      <Advertisement />
      <Hidden mdDown>
      <Dropdowns />
      <TitleWithSearch title={'OUR RANGE'} />
      </Hidden>      
      <OurRange />
    </>
  );
}

export default Home;
